import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView } from 'mdbreact';

import CarouselItemDesktop from './carouselitemdesktop';
import CarouselItemMobile from './carouselitemmobile';
import React from 'react';

const CarouselPage = (props) => {
    return (
        <MDBCarousel activeItem={1} length={props.CarouselItems.length}>
            <MDBCarouselInner>
                {props.CarouselItems.map((node, key) => {
                    let to = node.field_link_to_the_page
                    let localUrl = props.allMediaFile.edges.filter(mediaFile => {
                        return cUrl(mediaFile.node.relationships.field_media_file.uri.url) === cUrl(to)
                    });
                    if (localUrl[0]){
                        let fileLocation = localUrl[0].node.relationships.field_media_file.localFile.publicURL
                        to = encodeURI(fileLocation.substr(1))
                    }
                    return (
                        <MDBCarouselItem itemId={key+1} key={key}>
                            <MDBView>
                                <CarouselItemDesktop 
                                    to={to}
                                    nameid={`image-carousel-${key}`}
                                    image={encodeURI(node.relationships.desktop.localFile.childImageSharp.original.src)}
                                    width={node.relationships.desktop.localFile.childImageSharp.original.width}
                                    height={node.relationships.desktop.localFile.childImageSharp.original.height}
                                />
                                <CarouselItemMobile 
                                    to={to}
                                    nameid={`image-carousel-${key}`}
                                    image={encodeURI(node.relationships.mobile.localFile.childImageSharp.original.src)}
                                    width={node.relationships.mobile.localFile.childImageSharp.original.width}
                                    height={node.relationships.mobile.localFile.childImageSharp.original.height}
                                />
                            </MDBView>
                        </MDBCarouselItem>
                    )
                })}
            </MDBCarouselInner>
        </MDBCarousel>
    )
}

const cUrl = (string)  => {
    return string.replace(/(\/)/g, '').toLowerCase()
}

export default CarouselPage