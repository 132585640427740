import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import Events from '../happenings/events';
import React from 'react';
import VideoSelect from '../videos/videoselect/brightcovehomevideoselect';
import { isMobileOnly } from "react-device-detect";
import styled from 'styled-components';

const MDBContainerComp = styled(MDBContainer)`
    margin-top:30px;
    @media only screen and (max-width: 767px) {
        margin-top:inherit;
    }
`

const BottomSections = (props) => {
    return (
        <MDBContainerComp tag="section">
            <MDBRow center>
                {(!isMobileOnly) && 
                <MDBCol xs="12" md="6" lg="6" className="col-12">
                    <VideoSelect HomeVideos={props.HomeVideos}/>
                </MDBCol>}
                <MDBCol xs="12" md="6" lg="6" className="col-12">
                    <h3 style={{fontSize: `1.5em`}} className="text-center white-bk-Genesis d-block w-100 m-0 py-2">Events at Genesis</h3>
                    <Events EventList={props.EventList}/>
                </MDBCol>
            </MDBRow>
        </MDBContainerComp>
    )
}

export default BottomSections;