import React, { useEffect, useState } from "react";

import { Link } from 'gatsby';

const CarouselItem = (props) => {
    
    const isClient = typeof window === 'object';
    const url = props.to.toLowerCase()
    const [showChild, setShowChild] = useState(false)
    const [wWidth, setWWidth] = useState({});
    const [carousel, setCarousel] = useState(<span className="imgHP"/>);

    function getCarousel (wWidth, width, height) {
        setWWidth(wWidth)
        let proportion = 2.1
        let bannerWidth = (wWidth < width*proportion) ? wWidth : width*proportion
        let bannerHeight = bannerWidth*height/width
        let styleCarousel = {
            backgroundImage: `url(${props.image})`,
            width: bannerWidth,
            height: bannerHeight
        }
        let titleCarousel = {marginTop: bannerHeight/4}

        setCarousel(
            <span className="imgHP" style={styleCarousel}>
                <span className="d-none">{props.image}</span>
                {(props.title || props.subTitle) ?
                <><h1 className="carousel" style={titleCarousel}>{props.title}</h1>
                <h2 className="carousel" style={titleCarousel}>{props.subTitle}</h2></>:
                <><h1 className="invisible" style={titleCarousel}>no title</h1>
                <h2 className="invisible" style={titleCarousel}>no subtitle</h2></>}
            </span>
        )
    }

    useEffect(() => {setShowChild(isClient)}, [!showChild])
    useEffect(() => {getCarousel(window.innerWidth, props.width, props.height)}, [showChild])

    useEffect(() => {
        const handleResize = () => getCarousel(window.innerWidth, props.width, props.height);
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <span className={`d-none d-md-block`}>
            {(url.includes(`http://`) || 
            url.includes(`https://`) || 
            url.includes(`.pdf`)) ?
                <a aria-label={props.nameid} href={props.to}>{carousel}</a> :
                <Link aria-label={props.nameid} to={`/${props.to}`}>{carousel}</Link>}
        </span>       
    )
}

export default CarouselItem;