import { MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';

import InlineBodyTransform from '../inlinebodytransform';
import React from 'react';
import styled from 'styled-components';

const MDBCardComp = styled(MDBCard)`
    height: 98%;
    div.Ripple-parent{ 
        text-align: center;
        min-height: 180px;
        line-height: 180px;
        img {
            margin: 0 auto;
            display: inline;
        }
    }
    div.card-body {
        text-align: center;
        padding-left:8px;
        padding-right:8px;
        a[role="button"]{
            margin-bottom: 20px!important;
            min-width:80%;
        }
        p {text-align:left;}
    }
    div.heightBox {
        min-height: 250px;
        @media only screen and (max-width: 960px) {
            min-height: 200px;  
        }
    }
`

const MDBBtnComp = styled(MDBBtn)`
    width:98%;
    height:16%;
    background-color:#fff!important;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    color:#e61d26!important; 
    text-align: left;
    padding-top: 10%;
    padding-left:1rem;
    @media only screen and (max-width: 1199px) {
        height:11%;
        padding-left:1rem;
        padding-right:1rem;
    }
    @media only screen and (max-width: 991px) {
        height:27%;
        padding-left:2.14rem;
        padding-right:2.14rem;
    }
    @media only screen and (max-width: 767px) {
        height:40%;
    }
    @media only screen and (max-width: 575px) {
        height:38%;
    }  
    @media only screen and (max-width: 465px) {
        height:32%;
    }
    @media only screen and (max-width: 375px) {
        height:20%;
    } 
`

const CardExample = (props) => {
    return (
        <MDBCardComp>
            <MDBCardBody>
                <MDBBtnComp
                    color="white"
                    gradient="#fff"
                    href={props.link}
                    style={{backgroundImage: `url(${encodeURI(props.image)})`}}>
                    {props.headerText}
                </MDBBtnComp>
                <div className="card-text heightBox">
                    <InlineBodyTransform  bodyValue={props.Description} />
                </div>
            </MDBCardBody>
        </MDBCardComp>
    )
}

export default CardExample;