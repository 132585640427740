import { MDBCard, MDBCardBody } from 'mdbreact';

import InlineBodyTransform from '../../inlinebodytransform';
import React from 'react';
import styled from "styled-components";

let gmdv = require(`../../../ghc_config`);

const MDBCardComp = styled(MDBCard)`
    box-shadow: none;
    div.marginvideo{
        margin-top: 1.5em;
    }
`

const MDBCardBodyComp = styled(MDBCardBody)`
    height: 340px;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    div video {
        border-radius: 1.25rem;
    }
    @media only screen and (max-width: 1200px) {
        height:260px;
    }
    @media only screen and (max-width: 992px) {
        height:200px;
    }
    @media only screen and (max-width: 760px) {
        height:300px;
    }
`

const VideoHome = (props) => {
    return (
        <MDBCardComp>
            <MDBCardBodyComp>
                <iframe 
                    title={props.videoId}
                    id={props.videoId}
                    src={gmdv.getBcString(props.videoId)}
                    allowFullScreen="" 
                    allow="encrypted-media" 
                    className={`brightcove-react-player-loader-genesis id_${props.videoId}`}>
                </iframe>
                <div className="marginvideo card-text text-left">
                    <InlineBodyTransform bodyValue={props.description} />
                </div>
            </MDBCardBodyComp>
        </MDBCardComp>
    ) 
}

export default VideoHome