import { MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';

import InlineBodyTransform from '../inlinebodytransform';
import React from 'react';

const HPfooter = (props) => {
    return (
        <MDBContainer tag="section" className="mt-5 mb-4">
            <MDBRow center>
                {props.AboveFooter.map((node, key) => {
                    let BodyText = ''
                    let classAdded = ''
                    if (key == 2) {classAdded = ' alarmemail'}
                    {node.field_icon_image 
                        ?   BodyText = (<>
                        <MDBIcon icon={`${node.field_icon_image.icon_name}`} size="3x" 
                        className="red-text-Genesis text-center block w-100" /><br/><br/>
                        <InlineBodyTransform bodyValue={node.field_body}/>
                        </>)
                        :   BodyText = <InlineBodyTransform bodyValue={node.field_body}/>
                    }
                    return(
                        <MDBCol xs="12" md="4" lg="4" key={key} className="col-12 p-2">
                            <MDBCardBody className={`shadow p-3 bg-white rounded h-100${classAdded}`}>
                                {BodyText}
                            </MDBCardBody>
                        </MDBCol>
                    )
                })}
            </MDBRow>
        </MDBContainer>
    )
}

export default HPfooter;