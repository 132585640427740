import AboutSection from '../components/home/aboutsection';
import BottomSections from '../components/home/bottomsections';
import CardSections from '../components/home/cardsections';
import Carousel from '../components/home/carousel';
import HPFooter from '../components/home/homepagefooter';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import { graphql } from "gatsby";

const App = ({ location, data }) => {
    const CarouselItems = data.banners.edges[0].node.relationships.field_banners
    const allMediaFile = data.allMediaFile
    const ContactsBoxes = data.top_contact_icons.edges[0].node.relationships.field_top_contact_icons
    const CardsContent = data.middle_sections.edges[0].node.relationships.field_middle_sections
    const HomeVideos = data.allParagraphVideo
    const Happenings = data.allNodeCorporateHappenings
    const AboveFooter = data.above_footer.edges[0].node.relationships.field_above_footer

    return (
      <>
        <Carousel CarouselItems={CarouselItems} allMediaFile={allMediaFile} />
        <Layout loc={location.pathname}>
          <SEO 
            title="Long Term Care Services, Transitional Care, Rehab"
            description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
            keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
          />
          <Helmet>
            <meta name="google-site-verification" content="1PQ_7q0k6-b46Al3kbm5XyvLymj7zwP2xP1b72wx90w" />
          </Helmet>
        <main>
          <AboutSection 
            ContactsBoxes={ContactsBoxes} 
            HomeVideos={HomeVideos}/>
          <CardSections CardsContent={CardsContent} />
          <BottomSections
              HomeVideos={HomeVideos}
              EventList={Happenings} /> 
          <HPFooter AboveFooter={AboveFooter}/>
        </main>
        </Layout>
      </>
    );
}

export default App;

export const query = graphql`
query HomeData {

  banners: allNodeIndexPage(sort: {fields: relationships___field_banners, order: ASC}) {
    edges {
      node {
        relationships {
          field_banners {
            field_link_to_the_page
            relationships {
              desktop: field_image {
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
              mobile: field_banner_mobile_version {
                localFile {
                  childImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  top_contact_icons: allNodeIndexPage(sort: {fields: relationships___field_top_contact_icons, order: ASC}) {
    edges {
      node {
        relationships {
          field_top_contact_icons {
            field_title
            field_icon
            field_body {
              value
            }
          }
        }
      }
    }
  }

  middle_sections: allNodeIndexPage(sort: {fields: relationships___field_middle_sections, order: ASC}) {
    edges {
      node {
        relationships {
          field_middle_sections {
            field_body {
              value
            }
            field_title
            field_link_to_the_page
            relationships {
              field_image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
 
  allNodeCorporateHappenings(limit: 3, sort: {fields: field_happening_s_date, order: DESC}) {
    edges {
      node {
        field_happening_s_date
        title
        body {
          value
        }
        path {
          alias
        }
      }
    }
  }

  allParagraphVideo(
    filter: {
      field_title: {ne: null}, 
      relationships: {node__index_page: {elemMatch: {id: {eq: "73a1cfb4-a79d-537c-85b6-12c8450e0c03"}}}}}) {
    edges {
      node {
        id
        field_title
        field_brightcovevideo_id
        field_brightcovevideo_link
        field_video_description {
          value
        }
        relationships {
          field_thumbnailurl {
            localFile {
              childImageSharp {
                thumbnailHome: original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }

  above_footer: allNodeIndexPage(sort: {fields: relationships___field_above_footer, order: ASC}) {
    edges {
      node {
        relationships {
          field_above_footer {
            field_icon_image {
              icon_name
            }
            field_body {
              value
            }
          }
        }
      }
    }
  }

  allMediaFile {
    edges {
      node {
        relationships {
          field_media_file {
            uri {
              url
            }
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
}
`