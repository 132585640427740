import { MDBCard, MDBCardBody } from 'mdbreact';
import React, { useState } from 'react';

import Video from './brightcovevideoshome';
import styled from 'styled-components';

const SelectComp = styled.select`
  :focus{
    border-color: #ee1C25!important;
    box-shadow: 0 0 0 0.2rem #ffc3c3!important;
  }`;

const MDBCardComp = styled(MDBCard)`
  box-shadow: none;
  div.marginvideo{
      margin-top: 1.5em;
  }
`

const MDBCardBodyComp = styled(MDBCardBody)`
  height: 340px;
  padding: 0px;
  margin: 0px;
  box-shadow: none;
  div video {
      border-radius: 1.25rem;
  }
  @media only screen and (max-width: 1200px) {
      height:260px;
  }
  @media only screen and (max-width: 992px) {
      height:200px;
  }
  @media only screen and (max-width: 760px) {
      height:300px;
  }
`

const HomeVideoSelect = (props) => {

  let firstSelection = `6144420146001`
  if (props.HomeVideos.edges[0].node.field_brightcovevideo_id) {
    firstSelection = props.HomeVideos.edges[0].node.field_brightcovevideo_id.toString().trim()
  }
  const [selectVideo, setSelectVideo] = useState(firstSelection) 

  const handleSelectVideoChange = () => {
    let pauseID = selectVideo
    let iframeTag = document.querySelector(`.id_${pauseID}`)
    let iframeSrc = iframeTag.src
    iframeTag.src = iframeSrc
    setSelectVideo(event.target.value)
  }

  return(
    <div>
      <SelectComp 
        id="selectVideo"
        outline size="sm"
        className="form-control"
        aria-label="selectVideo"
        value={selectVideo} 
        onChange={() => handleSelectVideoChange()}>
        {props.HomeVideos.edges ? props.HomeVideos.edges.map((selecteditem, key) => {
          return(
          <option 
            key={key} 
            value={selecteditem.node.field_brightcovevideo_id.toString().trim()}>
            {[selecteditem.node.field_title]}
          </option>)
        }) : null}
      </SelectComp>
      {
        props.HomeVideos.edges ? 
        props.HomeVideos.edges.map((videoItem, key) => {
          let videoId = videoItem.node.field_brightcovevideo_id.toString().trim()
          let displayAttr = "none"
          if (videoId != selectVideo) {displayAttr = "none"} else {displayAttr = "block"};

          return(
            <div key={key} style={{display: displayAttr}}>
              <MDBCardComp>
                <MDBCardBodyComp>
                  <Video 
                    videoId = {videoId}
                    description = {videoItem.node.field_video_description} />
                 </MDBCardBodyComp>
              </MDBCardComp>
            </div>
          )
        }) : null
      }
    </div>
    )
  }

export default HomeVideoSelect