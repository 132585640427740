import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact'

import { Link } from 'gatsby'
import React from 'react'
import Updates from '../updates/updates'
import VideoSelect from '../videos/videoselect/brightcovehomevideoselect'
import { isMobileOnly } from 'react-device-detect'
import styled from 'styled-components'

const MDBContainerComp = styled(MDBContainer)`
  @media only screen and (max-width: 767px) {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
`

const LinkComp = styled(Link)`
  display: block;
  width: 100%;
`

const aStyle = {
  display: 'block',
  width: '100%',
}

const FeaturesPage = (props) => {
  return (
    <MDBContainerComp tag="section" className="text-center my-5">
      <MDBRow>
        {/* Lori requested to keep this */}
        {/* <MDBCol md="6">
                    <Updates/>
                </MDBCol> */}
        {props.ContactsBoxes.map((node, key) => {
          let baseContent = (
            <>
              <h5 className="font-weight-bold my-sm-2 my-md-4 black-text-Genesis gray-shadow">
                {node.field_title}
              </h5>
              <div className="gray-text-Genesis light-gray-shadow mb-md-0 mb-2">
                <div
                  dangerouslySetInnerHTML={{ __html: node.field_body.value }}
                />
              </div>
            </>
          )
          let valueIcon = ''
          let linkIcon = ''
          switch (node.field_icon) {
            case 'Find a Location':
              valueIcon = (
                <MDBIcon
                  icon="fas fa-map-marked-alt"
                  size="3x"
                  className="red-text-Genesis"
                />
              )
              linkIcon = (
                <LinkComp to="/findlocations">
                  {valueIcon}
                  {baseContent}
                </LinkComp>
              )
              break
            case '1.866.745.Care':
              //valueIcon = <img src="/images/avatars/phone-classic.png" alt="phone classic avatar" />
              valueIcon = (
                <MDBIcon
                  icon="fas fa-info-circle"
                  size="3x"
                  className="phone classic avatar"
                />
              )
              linkIcon = (
                <a className="ap-open" style={aStyle}>
                  {valueIcon}
                  {baseContent}
                </a>
              )
              break
            default:
              valueIcon = (
                <MDBIcon
                  icon="fas fa-map-marked-alt"
                  size="3x"
                  className="red-text-Genesis"
                />
              )
              linkIcon = (
                <LinkComp to="/findlocations">
                  {valueIcon}
                  {baseContent}
                </LinkComp>
              )
          }
          return (
            <MDBCol md="6" key={key}>
              {linkIcon}
            </MDBCol>
          )
        })}
      </MDBRow>
      {isMobileOnly && (
        <MDBRow center>
          <MDBCol xs="12" md="12" lg="12" className="col-12">
            <VideoSelect HomeVideos={props.HomeVideos} />
          </MDBCol>
        </MDBRow>
      )}
    </MDBContainerComp>
  )
}

export default FeaturesPage
