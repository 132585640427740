import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import Card from './card';
import React from 'react';

const cardSections = (props) => {
  return (
    <MDBContainer tag="section">
      <MDBRow center>
        {props.CardsContent.map((node, key) => {
          return (
            <MDBCol xs="12" md="6" lg="3" key={key} className="col-12">
              <Card
                image={node.relationships.field_image.localFile.childImageSharp.original.src}
                headerText={node.field_title}
                Description={node.field_body}
                link={node.field_link_to_the_page}
              />
            </MDBCol>
        )})}
      </MDBRow>
    </MDBContainer>
  )
}

export default cardSections;